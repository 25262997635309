.cls-1,
.cls-2 {
  fill: none;
}
.cls-1,
.cls-2,
.cls-3 {
  stroke: #000;
}
.cls-2 {
  stroke-dasharray: 4;
}
.cls-3 {
  fill: #fff;
  stroke-miterlimit: 2;
}
#ctrRotate {
  cursor: alias;
}
.ctrRotate {
  cursor: alias;
}
.ctrResize {
  cursor: nwse-resize;
}
.st0 {
  fill: none;
  stroke: #000000;
}
.st1 {
  fill: none;
  stroke: #000000;
  stroke-dasharray: 4;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 2;
}
.st3 {
  fill: #ffffff;
  stroke: #000000;
}
.st4 {
  fill: #ffffff;
  stroke: #000000;
  stroke-miterlimit: 2;
}
.point {
  stroke: #000000;
}
.moving {
  cursor: move;
}
.mask {
  cursor: move;
  fill: 'red';

  stroke: pink;
}
.curves {
  stroke: black;
  fill: none;
}

.control-line {
  stroke: green;
  fill: none;
}

.handle {
  fill: red;
  opacity: 0.5;
  cursor: move;
}

.handle-text {
  font-size: 12px;
  font-family: 'Noto Sans Japanese', sans;
}
